import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = { Authorization: `Bearer ${token}` };

const baseURL = process.env.VUE_APP_BASE_URL;

export function fetchBranches(pageNumber) {
  return axios.get(`${baseURL}/branch?page=${pageNumber}`, {
    headers: header,
  });
}

export function fetchBranch(branchId) {
  return axios.get(`${baseURL}/branch/${branchId}`, {
    headers: header,
  });
}

export function createBranch(payload) {
  return axios.post(
    `${baseURL}/branch/create`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function editBranch(id, payload) {
  return axios.put(
    `${baseURL}/branch/${id}`,
    { ...payload },
    { headers: header }
  );
}

export function deleteABranch(id) {
  return axios.delete(`${baseURL}/branch/${id}`, { headers: header });
}

export function uploadToCloudinary(formData) {
  return axios.post(
    "https://api.cloudinary.com/v1_1/trusoft-ng/image/upload",
    formData
  );
}
