<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submitStepTwo" class="text-left mt-10">
      <ValidationProvider
        rules="required"
        v-slot="{ errors }"
        name="Corpse Image"
      >
        <input
          type="file"
          ref="img"
          accept="image/*"
          class="hidden"
          v-on:change="uploadImg"
        />
        <div
          class="bg-gray-300 bg-opacity-30 px-5 py-20 cursor-pointer rounded-full my-5 mx-auto w-3/4 flex items-center justify-center"
          :class="errors[0] ? 'border border-red-400' : ''"
          @click="$refs.img.click()"
          v-if="corpse.image === ''"
        >
          <CameraIcon />
        </div>
        <img
          :src="corpse.image"
          class="rounded-full w-3/4 h-auto my-5 mx-auto border border-primary p-2"
          v-else
        />
        <span class="block text-red-400 mt-3" v-if="errors[0]">
          {{ errors[0] }}
        </span>
      </ValidationProvider>
      <div class="mb-5">
        <label class="block mb-2">Name of Deceased</label>
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <input
            id="name"
            type="text"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border border-red-400' : ''"
            placeholder="Jack"
            v-model="corpse.name_of_deceased"
          /><br />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Age of Deceased</label>
        <ValidationProvider name="age" rules="required" v-slot="{ errors }">
          <input
            id="age"
            type="text"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border border-red-400' : ''"
            placeholder="79"
            v-model="corpse.age_of_deceased"
          /><br />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Gender of Deceased</label>
        <ValidationProvider name="Gender" rules="required" v-slot="{ errors }">
          <select
            v-model="corpse.gender_of_deceased"
            placeholder="Gender"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Cause of death</label>
        <ValidationProvider
          name="Cause of death"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            id="cause"
            type="text"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border border-red-400' : ''"
            placeholder="Car accident"
            v-model="corpse.cause_of_death"
          /><br />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Time of Admission</label>
        <ValidationProvider
          name="Time of Admission"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            id="cause"
            type="time"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border border-red-400' : ''"
            v-model="corpse.time_of_admission"
          /><br />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Date of Admission</label>
        <ValidationProvider
          name="Date of Admission"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            id="cause"
            type="date"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border border-red-400' : ''"
            v-model="corpse.date_of_admission"
          /><br />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Contact Person</label>
        <ValidationProvider
          name="Contact Person"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            id="name"
            type="text"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border border-red-400' : ''"
            placeholder="Jack"
            v-model="corpse.contact_person"
          /><br />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Contact Person’s Phone Number</label>
        <ValidationProvider
          name="phone"
          rules="required|length:11"
          v-slot="{ errors }"
        >
          <input
            id="phone"
            type="tel"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border border-red-400' : ''"
            placeholder="070123123123"
            v-model="corpse.contact_person_phone"
          /><br />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Fridge Number</label>
        <ValidationProvider
          name="Fridge Number"
          rules="required"
          v-slot="{ errors }"
        >
          <select
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border border-red-400' : ''"
            placeholder="79"
            v-model="corpse.fridge_number"
          >
            <option v-for="fridge in availableFridges" :key="fridge.id">
              {{ fridge.fridge_description }}
            </option></select
          ><br />
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Embalmment Status</label>
        <ValidationProvider
          name="Embalmment Status"
          rules="required"
          v-slot="{ errors }"
        >
          <select
            v-model="corpse.embalmment_status"
            placeholder="Gender"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
          >
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Funeral Services</label>
        <ValidationProvider
          name="Funeral Services"
          rules="required"
          v-slot="{ errors }"
        >
          <select
            v-model="corpse.funeral_service"
            placeholder="Yes"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
          >
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Repatriation</label>
        <ValidationProvider
          name="Repatriation"
          rules="required"
          v-slot="{ errors }"
        >
          <select
            v-model="corpse.repatriation"
            placeholder="Yes"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
          >
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Personal Possession on Arrival</label>
        <ValidationProvider
          name="Personal Possession on Arrival"
          v-slot="{ errors }"
        >
          <textarea
            v-model="corpse.personal_possession_on_arrival"
            placeholder="Chains, Watch etc"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
          >
          </textarea>
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <div class="mb-5">
        <label class="block mb-2">Comment</label>
        <ValidationProvider name="Comment" v-slot="{ errors }">
          <textarea
            v-model="corpse.comment"
            placeholder="Chains, Watch etc"
            class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
            :class="errors[0] ? 'border-red-400 border' : ''"
          >
          </textarea>
          <span class="block text-red-400 mt-3" v-if="errors[0]">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </div>
      <button
        class="w-full mt-10 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
        :class="invalid ? 'bg-gray-300' : 'bg-primary'"
        :disabled="invalid || submitting"
      >
        {{ submitting ? "Please wait..." : "Create corpse" }}
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { uploadToCloudinary } from "@/services/branch";

import { secondstep, fetchFridge } from "@/services/addmission";

export default {
  props: {
    corpse_id: {
      required: true,
      type: String,
    },
    corpseData: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      corpse: {
        corpse_id: "", // Gotten from BE
        image: "",
        name_of_deceased: "",
        age_of_deceased: "",
        gender_of_deceased: "",
        cause_of_death: "",
        time_of_admission: "",
        date_of_admission: "",
        contact_person: "",
        contact_person_phone: "",
        fridge_number: "",
        embalmment_status: false,
        funeral_service: false,
        repatriation: false,
        personal_possession_on_arrival: "",
        comment: "",
      },
      submitting: false,
      imgPlaceholder: true,
      availableFridges: [],
    };
  },
  components: {
    CameraIcon: () => import("@/assets/icons/CameraIcon.vue"),
  },
  methods: {
    async submitStepTwo() {
      this.submitting = true;
      const payload = { ...this.corpse, corpse_id: this.corpse_id };
      try {
        const response = await secondstep(payload);
        this.submitting = false;
        this.$emit("created", response.data.corpse);
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
      this.submitting = false;
    },
    async uploadImg(e) {
      const img = e.target.files[0];

      let formData = new FormData();
      formData.append("upload_preset", "branchImages");
      formData.append("folder", "tos-project");
      formData.append("file", img);
      try {
        const response = await uploadToCloudinary(formData);
        this.corpse.image = response.data.url;
        this.imgPlaceholder = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAvailableFridges() {
      try {
        const response = await fetchFridge();
        this.availableFridges = response.data.fridge.map((fridge) => {
          if (fridge.fridge_status === "0") {
            return fridge;
          }
        });
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response.data.message || error);
      }
    },
  },
  mounted() {
    this.fetchAvailableFridges();
    if (this.corpseData) {
      this.corpse = this.corpseData;
    }
  },
};
</script>
